<script setup lang="ts">
import { UseElementBounding } from "@vueuse/components";

const emojis = [
  "/images/icons/heart.png",
  "/images/icons/heart-2.png",
  "/images/icons/like.png",
  "/images/icons/like-2.png",
  "/images/icons/like-3.png",
  "/images/icons/smile.png",
  "/images/icons/smile-2.png",
  "/images/icons/smile-3.png",
];

function getPositionStyle(index: number) {
  const radius = 50;
  const angle = (index / emojis.length) * 2 * Math.PI;
  const x = Math.cos(angle) * 350;
  const y = Math.sin(angle) * 350;
  return {
    left: `${x}px`,
    top: `${y}px`,
  };
}
</script>
<template>
  <UseElementBounding v-slot="{ top, height }">
    <UtilsSection
      class="bg-[url(/images/backgrounds/instagram-bg.jpg)] bg-cover bg-[bottom_center] overflow-hidden pb-20"
      container-class="flex flex-col items-center justify-center relative"
    >
      <template #default>
        <div class="flex flex-col items-center justify-center relative z-10">
          <img src="/images/elements/crown.png" class="w-32 mb-10" />
          <h3 class="text-white text-4xl uppercase mb-8 text-shadow text-center">
            Join the Story
          </h3>
          <div
            class="bg-[url(/images/elements/box-cream.png)] aspect-[0.90] bg-cover md:w-96  items-center flex justify-center flex-col px-10 text-center text-gray-900 leading-none"
          >
            <img src="/images/elements/instagram-author.png" class="w-48" />
            <h3 class="text-3xl font-semibold tracking-tight mb-1">
              {{ $t("instagram.title-1") }}
            </h3>
            <p>
              {{ $t("instagram.title-2") }}
            </p>
            <p class="mt-3 font-semibold">
              {{ $t("instagram.title-3") }}
            </p>
          </div>
          <div class="mt-10">
            <a href="https://www.instagram.com/xmondodigital/" target="_blank">
              <img src="/images/elements/follow-button.png" class="w-56" />
            </a>
          </div>
        </div>
        <div
          class="absolute flex items-center justify-center w-[600px] h-[600px] left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2"
        >
          <div
            class="animate-spin w-full h-full flex items-center justify-center"
            :style="{
              animationDuration: '20s',
            }"
          >
            <div class="h-[75px] w-[75px] relative">
              <img
                :src="emoji"
                v-for="(emoji, index) in emojis"
                class="absolute"
                :style="getPositionStyle(index + 1)"
              />
            </div>
          </div>
        </div>
      </template>
    </UtilsSection>
  </UseElementBounding>
</template>
