<script setup lang="ts">
const { locale } = useI18n();

const bookImage = computed(() => {
  if (locale.value === "it") {
    return "/images/elements/a-arte-da-influencia-livro-comprar-it.png";
  }
  return "/images/elements/a-arte-da-influencia-livro-comprar.png";
});
</script>
<template>
  <UtilsSection
    container-class="md:flex-row flex-col flex gap-5 items-center relative z-10"
    class="relative overflow-hidden bg-[#F5FCFE]"
  >
    <div>
      <img data-aos="fade-right" :src="bookImage" class="w-full" />
    </div>
    <div class="md:w-[60%] w-full flex flex-col items-center">
      <!-- <div
        class="md:inline-flex items-center justify-center relative mb-8 hidden"
      >
        <UtilsTitle
          class="text-center text-[#98ADC6] font-medium mt-4 text-5xl"
          data-aos="fade-up"
        >
          Compre
        </UtilsTitle>
        <div
          class="font-neutron font-normal text-[5rem] italic text-[#EE3824] text-center"
          data-aos="fade-left"
        >
          Agora
        </div>
        <SvgoScribble
          class="w-[150px] absolute -right-2 -bottom-2"
          :font-controlled="false"
          :filled="true"
        />
      </div> -->
      <div class="text-center flex flex-col items-center [&>p]:py-2 w-full">
        <p data-aos="fade-up" class="mb-10">
          {{ $t("buy-now.description-p-1") }}
          <strong>"{{ $t("book-title") }}"</strong>
          {{ $t("buy-now.description-p-2") }}
        </p>

        <div data-aos="fade-up">
          <div
            class="font-neutron italic text-3xl max-w-72 text-transparent bg-clip-text bg-gradient-to-b from-[#FF6B3D] via-[#EE3824] to-[#D7311E] -rotate-3"
          >
            {{ $t("buy-now.description-p-5") }}
          </div>
        </div>
        <div data-aos="fade-up">
          <SvgoScribble4
            class="w-[200px] mt-3 mx-auto"
            :font-controlled="false"
            :filled="true"
          />
        </div>
        <div data-aos="fade-up">
          <UtilsButton
            class="pr-5 mt-5"
            as="a"
            href="#buy-now"
            scroll-to="buy-now"
          >
            {{ $t("join-waiting-list") }}
            <template #append>
              <Icon name="ic:round-arrow-right" class="text-3xl" />
            </template>
          </UtilsButton>
        </div>
      </div>
    </div>
    <template #append>
      <SvgoSquaresLeft
        class="w-[230px] absolute left-0 -top-[1px]"
        :font-controlled="false"
        :filled="true"
      />
      <SvgoSquaresRight
        class="w-[300px] absolute right-0 -bottom-[1px]"
        :font-controlled="false"
        :filled="true"
      />
    </template>
  </UtilsSection>
</template>
