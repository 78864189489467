<script setup lang="ts">
const { locale } = useI18n();
const currentItem = ref("item-1");
</script>

<template>
  <UtilsSection
    class="bg-[url(/images/backgrounds/faq-bg.jpg)] bg-cover bg-[center_bottom]"
  >
    <UtilsTitle class="text-[#27C9F4]" data-aos="fade-up">
      {{ $t("faq.title") }}
    </UtilsTitle>
    <SvgoArrowDown
      :font-controlled="false"
      :filled="true"
      class="w-10 absolute -ml-14 -mt-5"
    />
    <UtilsSubtitle class="text-[#98ADC6]/35 text-3xl" data-aos="fade-up"
      >Frequently Asked Questions</UtilsSubtitle
    >
    <div class="flex md:flex-row flex-col gap-8">
      <div class="flex mt-5 justify-center">
        <Accordion type="single" collapsible v-model="currentItem">
          <AccordionItem value="item-1" data-aos="fade-up">
            <AccordionTrigger>
              {{
                $t("faq.question-1", {
                  book: $t("book-title"),
                })
              }}
            </AccordionTrigger>
            <AccordionContent>
              {{
                $t("faq.answer-1", {
                  book: $t("book-title"),
                })
              }}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-2" data-aos="fade-up">
            <AccordionTrigger>
              {{ $t("faq.question-2") }}
            </AccordionTrigger>
            <AccordionContent>
              {{
                $t("faq.answer-2", {
                  book: $t("book-title"),
                })
              }}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-3" data-aos="fade-up">
            <AccordionTrigger>
              {{
                $t("faq.question-3", {
                  book: $t("book-title"),
                })
              }}
            </AccordionTrigger>
            <AccordionContent>
              {{ $t("faq.answer-3") }}
            </AccordionContent>
          </AccordionItem>
          <AccordionItem value="item-4" data-aos="fade-up">
            <AccordionTrigger>
              {{ $t("faq.question-4") }}
            </AccordionTrigger>
            <AccordionContent>
              {{
                $t("faq.answer-4", {
                  book: $t("book-title"),
                })
              }}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
      <div>
        <SvgoPlateIt
          v-if="locale === 'it'"
          :font-controlled="false"
          :filled="true"
          class="md:w-[500px] w-full"
          data-aos="fade"
        />
        <SvgoPlate
          v-else
          :font-controlled="false"
          :filled="true"
          class="md:w-[500px] w-full"
          data-aos="fade"
        />
      </div>
    </div>
  </UtilsSection>
</template>
