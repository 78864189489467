<script setup lang="ts">
const config = useRuntimeConfig();

const { t } = useI18n();

useSeoMeta({
  title: t("home.title"),
  description: t("home.description"),
  author: "Marcos Santi Scheffel",
});
</script>
<template>
  <SectionsHeader />
  <SectionsConquer />
  <UtilsSeparator position="left" />
  <SectionsTestimonials />
  <UtilsSeparator position="left" />
  <SectionsAuthor />
  <UtilsSeparator position="right" />
  <SectionsBuyNow />
  <UtilsSeparator position="right" />
  <SectionsVideo />
  <SectionsBuyOptions />
  <SectionsSubscribeForm />
  <UtilsSeparator position="right" />
  <SectionsFaq />
  <UtilsColoredSeparator />
  <SectionsInstagram />
  <SectionsFooter />
  <UtilsScrollup />
</template>
